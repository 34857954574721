import request from '@/utils/request'

export function getAllType(params) {
    return request({
      url: `/pc/file/type/all`,
      method: 'GET',
      params
    })
  }

  export function getSearchRecords(params) {
    return request({
      url: `/pc/document/archive`,
      method: 'GET',
      params
    })
  }