<template>
  <section style="margin: 0 24px">
    <div class="content">
      <div class="content-left">
        <a-checkbox v-model:checked="allChecked" @change="toggleAll" class="content-checkbox">全选</a-checkbox>
        <br />
        <div v-for="(item, index) in typeData" :key="index">
          <a-checkbox v-model:checked="checked[index]" class="content-checkbox" @change="toggleItem(index)">
            <a-tooltip placement="bottomLeft" v-if="item?.length > 8">
              <template #title>
                <span>{{ item }}</span>
              </template>
              {{ item ?? '-' }}
            </a-tooltip>
            <span v-else>{{ item ?? '-' }}</span></a-checkbox
          >
        </div>
      </div>
      <div class="content-right">
        <div class="content-search">
          <a-form layout="inline" ref="headFormRef" class="form-style" style="padding: 0;">
            <!-- 搜索框 -->
            <a-form-item class="form-item">
              <a-select v-model:value="formState.filename" style="width: 130px;">
                <a-select-option value="searchFileName">流程主题</a-select-option>
                <a-select-option value="searchSealName">印章名称</a-select-option>
              </a-select>
              <a-input v-model:value="formState.filevalue" style="width: 226px" placeholder="请输入" />
            </a-form-item>
            <a-form-item class="form-item">
              <a-select v-model:value="formState.personname" style="width: 130px;">
                <a-select-option value="searchStaffName">申请人</a-select-option>
                <a-select-option value="searchArchiveStaffname">归档人</a-select-option>
                <a-select-option value="searchAffixedStaffname">盖印人</a-select-option>
              </a-select>
              <a-input v-model:value="formState.personvalue" style="width: 226px" placeholder="请输入"
            /></a-form-item>
            <a-form-item class="form-item">
              <div style="display: flex;margin-top: 3px;">
                <a-select v-model:value="formState.mentname" style="width: 130px;" class="form-item-select">
                  <a-select-option value="departmentIdList">申请人部门</a-select-option>
                  <a-select-option value="archiveDepartmentIdList">归档人部门</a-select-option>
                  <a-select-option value="affixedDepartmentIdList">盖印人部门</a-select-option>
                </a-select>
                <DeptSelect v-model:deptList="formState.deptList" style="width: 225px;" />
              </div>
            </a-form-item>
            <a-form-item label="归档时间：" name="submitTime" class="form-item form-item-time">
              <a-range-picker
                :disabledDate="disabledDate"
                separator="→"
                allowClear
                v-model:value="formState.submitTime"
              >
                <template #suffixIcon>
                  <CalendarOutlined />
                </template>
              </a-range-picker>
            </a-form-item>
            <!-- 重置/查询 -->
            <a-form-item class="form-btns">
              <a-button @click="reset">重置</a-button>
              <a-button class="form-btn" @click="search">查询</a-button>
            </a-form-item>
          </a-form>
        </div>
        <section class="arch-table">
          <a-table
            :columns="recordsColumns"
            :data-source="tableData"
            :scroll="{ x: 400 }"
            :pagination="state.pagination"
            :loading="state.tableLoading"
            @change="handleTableChange"
            :rowKey="r => `${r.id}`"
            ref="tableRef"
          >
            <!-- 序号 -->
            <template #id="{ index }">{{
              (state.pagination.index - 1) * state.pagination.pageSize + index + 1
            }}</template>
            <!-- 流程主题 -->
            <template #fileName="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 12) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 文件类型 -->
            <template #fileTypeName="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 12) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 申请人 -->
            <template #staffName="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 10) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 盖印人 -->
            <template #affixedStaffname="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 10) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 归档人 -->
            <template #archiveStaffname="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 10) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 印章名称 -->
            <template #sealName="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 9) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <!-- 业务类型 -->
            <template #processType="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text">
                  <span v-if="text == '1'">普通申请</span>
                  <span v-if="text == '2'">自助申请</span>
                </a-tooltip>
                <span v-else>{{ '--' }}</span>
              </div>
            </template>
            <!-- 流程名称 -->
            <template #processName="{ text }">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text.slice(0, 12) }}...
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
            </template>
            <template #action="{ record }">
              <a style="color:#1890FF" @click="check(record)">查看</a>
            </template>
          </a-table>
        </section>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, reactive, ref, watch, defineProps, onUnmounted, nextTick } from 'vue'
import { getAllType } from '@/apis/recordsManage'
import { CalendarOutlined } from '@ant-design/icons-vue'
import recordsTable from './components/table.vue'
import moment from 'moment'
import { getArchiveType } from '@/apis/approval'
import DeptSelect from '@/views/businessManage/staff/components/staffModal/deptSelect.vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { recordsColumns } from './components/columns'
import { paginationParams } from '@/utils/constData'
// import { getSearchRecords } from '@/apis/recordsManage'
import { getArchiveList } from '@/apis/approval'
const router = useRouter()
const tableData = ref([])
const state = reactive({
  tableLoading: false,
  pagination: {
    ...paginationParams
  }
})

let params = reactive({})
const checked = ref([]) //文件类型对应的布尔
const allChecked = ref(true) //默认进入页面为全选
const clicktype = ref([]) //当前选中的文件类型
let typeData = ref([])

const updatList = () => {
  let filename = {}
  filename[formState.filename] = formState.filevalue
  let personname = {}
  personname[formState.personname] = formState.personvalue
  let mentname = {}
  mentname[formState.mentname] = formState.deptList.map(item => item.departmentId)
  params = {
    ...filename,
    ...personname,
    ...mentname,
    fileTypeNames: formState.fileTypeName,
    startTime: formState.submitTime.length ? `${moment(formState.submitTime[0]).format('YYYY-MM-DD 00:00:00')}` : '', //开始时间
    endTime: formState.submitTime.length ? `${moment(formState.submitTime[1]).format('YYYY-MM-DD 23:59:59')}` : '', //结束时间
    pageSize: state.pagination.pageSize,
    pageIndex: state.pagination.current //
  }
  console.log('56666', params)
  getList()
}
const check = record => {
  console.log('调到详情页', record)
  router.push({
    path: '/archiveManage/detail',
    query: {
      id: record.id,
      type: 1
    }
  })
}
const getList = async () => {
  await getArchiveList(params).then(res => {
    tableData.value = res.data
    console.log(res)
    state.pagination.total = res.totalItem
    state.pagination.index = res.pageIndex
  })
}
const handleTableChange = ({ current, pageSize }) => {
  // 页码change改变
  state.dataSource = []
  state.pagination.current = current
  state.currentNum = current
  state.pagination.pageSize = pageSize
  updatList()
}
const formState = reactive({
  filename: 'searchFileName',
  filevalue: undefined,
  personname: 'searchStaffName',
  personvalue: undefined,
  mentname: 'departmentIdList',
  mentvalue: undefined,
  submitTime: [],
  archiveDepartmentId: clicktype.value,
  fileTypeName: [],
  deptList: []
})
const reset = () => {
  formState.filename = 'searchFileName'
  formState.filevalue = undefined
  formState.personname = 'searchStaffName'
  formState.personvalue = undefined
  formState.mentname = 'departmentIdList'
  formState.mentvalue = undefined
  formState.submitTime = []
  formState.archiveDepartmentId = clicktype.value
  formState.fileTypeName = []
  state.pagination.current = 1
  formState.deptList = []
  allChecked.value = true
  toggleAll()
  state.pagination.current = 1
  updatList()
}
//多选框,全选
const toggleAll = () => {
  if (allChecked.value) {
    checked.value = typeData.value.map((_, index) => true)
  } else {
    checked.value = typeData.value.map((_, index) => false)
  }
  onCheckAllChange()
  console.log('全选', clicktype.value)
  formState.fileTypeName = clicktype.value
  state.pagination.current = 1
  updatList()
}
const toggleItem = index => {
  if (!checked.value[index]) {
    allChecked.value = false
  } else {
    const allCheckedCount = checked.value.filter(item => item).length
    allChecked.value = allCheckedCount === typeData.value.length
  }
  onCheckAllChange()

  console.log('单选', clicktype.value)
  formState.fileTypeName = clicktype.value
  state.pagination.current = 1
  updatList()
}
//获取当前选中的文件类型
const onCheckAllChange = e => {
  clicktype.value.length = 0
  for (let i = 0; i < checked.value.length; i++) {
    if (checked.value[i] == true) {
      clicktype.value.push(typeData.value[i])
    }
  }
}
const getTypeList = () => {
  getArchiveType().then(res => {
    console.log('typeall', res)
    if (res.success) {
      typeData.value = res.data
      for (let i = 0; i < typeData.value.length; i++) {
        checked.value.push(true)
      }
    }
  })
}
const search = () => {
  state.pagination.current = 1
  updatList()
}
// 日期选择器：当天之后不可选
const disabledDate = current => {
  return current && current > moment().endOf('day')
}
onMounted(async () => {
  await getTypeList()
  updatList()
})
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  border-radius: 4px;
  display: flex;
  padding: 0;
  flex-direction: row;

  .content-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 186px;
    padding: 24px 24px 16px;
    flex: 1;
    overflow: auto;
    .content-checkbox {
      width: 140px;
      height: 30px;
      font-size: 14px;
      padding-top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .content-right {
    margin-left: 187px;
    color: black;
    width: calc(100% - 186px);
    padding: 24px;
    transform: scale(1);
    background: #fff;
    min-height: 670px;

    .form-item {
      margin-bottom: 12px;
      :deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
        border-right: none;
      }
      .form-item-select {
        :deep(.ant-select-selector) {
          height: 34px;
        }
      }
    }

    .content-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
}
</style>

<style lang="less">
.arch-table {
  div {
    &::-webkit-scrollbar {
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 4px;
      width: 8px;
    }
  }
  :deep(.ant-table-body) {
    overflow: auto !important;
  }
}
</style>
